/*
File generated by js-routes 1.4.14
Based on Rails 7.1.4.1 routes of RiskManagement::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// access_control_risk_category => /:time_period/risk_categories/:id/access_control(.:format)
  // function(time_period, id, options)
  access_control_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"access_control",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// access_control_update_risk_category => /:time_period/risk_categories/:id/access_control_update(.:format)
  // function(time_period, id, options)
  access_control_update_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"access_control_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// access_rules_administration_document => /administration/documents/:id/access_rules(.:format)
  // function(id, options)
  access_rules_administration_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"access_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// access_rules_document => /documents/:id/access_rules(.:format)
  // function(id, options)
  access_rules_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"access_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// access_rules_form_administration_document => /administration/documents/:id/access_rules_form(.:format)
  // function(id, options)
  access_rules_form_administration_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"access_rules_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// access_rules_form_document => /documents/:id/access_rules_form(.:format)
  // function(id, options)
  access_rules_form_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"access_rules_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// add_risk_attribute_to_risk_component_risk_category => /:time_period/risk_categories/:id/add_risk_attribute_to_risk_component(.:format)
  // function(time_period, id, options)
  add_risk_attribute_to_risk_component_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"add_risk_attribute_to_risk_component",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_account_risk_entities => /administration/accounts/:account_id/risk_entities(.:format)
  // function(account_id, options)
  administration_account_risk_entities_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_account_risk_entity => /administration/accounts/:account_id/risk_entities/:id(.:format)
  // function(account_id, id, options)
  administration_account_risk_entity_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// administration_account_risk_entity_department_risk_entity_definition => /administration/accounts/:account_id/risk_entities/:risk_entity_id/department_risk_entity_definitions/:id(.:format)
  // function(account_id, risk_entity_id, id, options)
  administration_account_risk_entity_department_risk_entity_definition_path: Utils.route([["account_id",true],["risk_entity_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"department_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// administration_account_risk_entity_department_risk_entity_definitions => /administration/accounts/:account_id/risk_entities/:risk_entity_id/department_risk_entity_definitions(.:format)
  // function(account_id, risk_entity_id, options)
  administration_account_risk_entity_department_risk_entity_definitions_path: Utils.route([["account_id",true],["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"department_risk_entity_definitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// administration_account_risk_entity_line_of_business_risk_entity_definition => /administration/accounts/:account_id/risk_entities/:risk_entity_id/line_of_business_risk_entity_definitions/:id(.:format)
  // function(account_id, risk_entity_id, id, options)
  administration_account_risk_entity_line_of_business_risk_entity_definition_path: Utils.route([["account_id",true],["risk_entity_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"line_of_business_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// administration_account_risk_entity_line_of_business_risk_entity_definitions => /administration/accounts/:account_id/risk_entities/:risk_entity_id/line_of_business_risk_entity_definitions(.:format)
  // function(account_id, risk_entity_id, options)
  administration_account_risk_entity_line_of_business_risk_entity_definitions_path: Utils.route([["account_id",true],["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"line_of_business_risk_entity_definitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// administration_account_risk_entity_risk_entity_risk_category_settings => /administration/accounts/:account_id/risk_entities/:risk_entity_id/risk_entity_risk_category_settings(.:format)
  // function(account_id, risk_entity_id, options)
  administration_account_risk_entity_risk_entity_risk_category_settings_path: Utils.route([["account_id",true],["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"risk_entity_risk_category_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// administration_account_risk_entity_sync_lobdep_index => /administration/accounts/:account_id/risk_entities/:risk_entity_id/sync_lobdep(.:format)
  // function(account_id, risk_entity_id, options)
  administration_account_risk_entity_sync_lobdep_index_path: Utils.route([["account_id",true],["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"sync_lobdep",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// administration_data_input_definition_populate_risk_entities => /administration/data_input_definitions/:data_input_definition_id/populate_risk_entities(.:format)
  // function(data_input_definition_id, options)
  administration_data_input_definition_populate_risk_entities_path: Utils.route([["data_input_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"data_input_definitions",false],[2,[7,"/",false],[2,[3,"data_input_definition_id",false],[2,[7,"/",false],[2,[6,"populate_risk_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_document => /administration/documents/:id(.:format)
  // function(id, options)
  administration_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// administration_documents => /administration/documents(.:format)
  // function(options)
  administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// administration_fintech_vendor_fetch_status => /administration/fintech_vendors/:fintech_vendor_id/fetch_status(.:format)
  // function(fintech_vendor_id, options)
  administration_fintech_vendor_fetch_status_path: Utils.route([["fintech_vendor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"fintech_vendors",false],[2,[7,"/",false],[2,[3,"fintech_vendor_id",false],[2,[7,"/",false],[2,[6,"fetch_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_fintech_vendor_score_cb_insights_risks => /administration/fintech_vendors/:fintech_vendor_id/score_cb_insights_risks(.:format)
  // function(fintech_vendor_id, options)
  administration_fintech_vendor_score_cb_insights_risks_path: Utils.route([["fintech_vendor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"fintech_vendors",false],[2,[7,"/",false],[2,[3,"fintech_vendor_id",false],[2,[7,"/",false],[2,[6,"score_cb_insights_risks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_fintech_vendor_score_dun_bradstreet_risks => /administration/fintech_vendors/:fintech_vendor_id/score_dun_bradstreet_risks(.:format)
  // function(fintech_vendor_id, options)
  administration_fintech_vendor_score_dun_bradstreet_risks_path: Utils.route([["fintech_vendor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"fintech_vendors",false],[2,[7,"/",false],[2,[3,"fintech_vendor_id",false],[2,[7,"/",false],[2,[6,"score_dun_bradstreet_risks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_fintech_vendor_score_upguard_risks => /administration/fintech_vendors/:fintech_vendor_id/score_upguard_risks(.:format)
  // function(fintech_vendor_id, options)
  administration_fintech_vendor_score_upguard_risks_path: Utils.route([["fintech_vendor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"fintech_vendors",false],[2,[7,"/",false],[2,[3,"fintech_vendor_id",false],[2,[7,"/",false],[2,[6,"score_upguard_risks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_fintech_vendor_set_cb_insights_org_id_and_description => /administration/fintech_vendors/:fintech_vendor_id/set_cb_insights_org_id_and_description(.:format)
  // function(fintech_vendor_id, options)
  administration_fintech_vendor_set_cb_insights_org_id_and_description_path: Utils.route([["fintech_vendor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"fintech_vendors",false],[2,[7,"/",false],[2,[3,"fintech_vendor_id",false],[2,[7,"/",false],[2,[6,"set_cb_insights_org_id_and_description",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_fintech_vendor_set_cb_insights_organization_details => /administration/fintech_vendors/:fintech_vendor_id/set_cb_insights_organization_details(.:format)
  // function(fintech_vendor_id, options)
  administration_fintech_vendor_set_cb_insights_organization_details_path: Utils.route([["fintech_vendor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"fintech_vendors",false],[2,[7,"/",false],[2,[3,"fintech_vendor_id",false],[2,[7,"/",false],[2,[6,"set_cb_insights_organization_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_fintech_vendor_set_dun_bradstreet_company_matches => /administration/fintech_vendors/:fintech_vendor_id/set_dun_bradstreet_company_matches(.:format)
  // function(fintech_vendor_id, options)
  administration_fintech_vendor_set_dun_bradstreet_company_matches_path: Utils.route([["fintech_vendor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"fintech_vendors",false],[2,[7,"/",false],[2,[3,"fintech_vendor_id",false],[2,[7,"/",false],[2,[6,"set_dun_bradstreet_company_matches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_risk_attribute_definition_populate_risk_entities => /administration/risk_attribute_definitions/:risk_attribute_definition_id/populate_risk_entities(.:format)
  // function(risk_attribute_definition_id, options)
  administration_risk_attribute_definition_populate_risk_entities_path: Utils.route([["risk_attribute_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"risk_attribute_definitions",false],[2,[7,"/",false],[2,[3,"risk_attribute_definition_id",false],[2,[7,"/",false],[2,[6,"populate_risk_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_risk_attribute_indicator_definition_populate_risk_entities => /administration/risk_attribute_indicator_definitions/:risk_attribute_indicator_definition_id/populate_risk_entities(.:format)
  // function(risk_attribute_indicator_definition_id, options)
  administration_risk_attribute_indicator_definition_populate_risk_entities_path: Utils.route([["risk_attribute_indicator_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_definitions",false],[2,[7,"/",false],[2,[3,"risk_attribute_indicator_definition_id",false],[2,[7,"/",false],[2,[6,"populate_risk_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_risk_improvement_activity_source_definition_populate_risk_entities => /administration/risk_improvement_activity_source_definitions/:risk_improvement_activity_source_definition_id/populate_risk_entities(.:format)
  // function(risk_improvement_activity_source_definition_id, options)
  administration_risk_improvement_activity_source_definition_populate_risk_entities_path: Utils.route([["risk_improvement_activity_source_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"risk_improvement_activity_source_definitions",false],[2,[7,"/",false],[2,[3,"risk_improvement_activity_source_definition_id",false],[2,[7,"/",false],[2,[6,"populate_risk_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_self_assessment_consideration_definition_populate_risk_entities => /administration/self_assessment_consideration_definitions/:self_assessment_consideration_definition_id/populate_risk_entities(.:format)
  // function(self_assessment_consideration_definition_id, options)
  administration_self_assessment_consideration_definition_populate_risk_entities_path: Utils.route([["self_assessment_consideration_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"self_assessment_consideration_definitions",false],[2,[7,"/",false],[2,[3,"self_assessment_consideration_definition_id",false],[2,[7,"/",false],[2,[6,"populate_risk_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// administration_snowflake_data_source_definition_populate_risk_entities => /administration/snowflake_data_source_definitions/:snowflake_data_source_definition_id/populate_risk_entities(.:format)
  // function(snowflake_data_source_definition_id, options)
  administration_snowflake_data_source_definition_populate_risk_entities_path: Utils.route([["snowflake_data_source_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"snowflake_data_source_definitions",false],[2,[7,"/",false],[2,[3,"snowflake_data_source_definition_id",false],[2,[7,"/",false],[2,[6,"populate_risk_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// alert_expired_session => /users/sessions/alert_expired_session(.:format)
  // function(options)
  alert_expired_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[6,"alert_expired_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// attach_administration_documents => /administration/documents/attach(.:format)
  // function(options)
  attach_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"attach",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// attach_documents => /documents/attach(.:format)
  // function(options)
  attach_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"attach",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// attributes_settings_overrides_attribute_override_risk_attributes => /settings/overrides/attribute_overrides/:attribute_override_id/risk_attributes/attributes(.:format)
  // function(attribute_override_id, options)
  attributes_settings_overrides_attribute_override_risk_attributes_path: Utils.route([["attribute_override_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"attribute_overrides",false],[2,[7,"/",false],[2,[3,"attribute_override_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[6,"attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// calculation_description_data_input => /:time_period/data_inputs/:id/calculation_description(.:format)
  // function(time_period, id, options)
  calculation_description_data_input_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"data_inputs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"calculation_description",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_data_inputs_time_period_time_periods => /time_periods/change_data_inputs_time_period(.:format)
  // function(options)
  change_data_inputs_time_period_time_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[6,"change_data_inputs_time_period",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// change_time_period_time_periods => /time_periods/change_time_period(.:format)
  // function(options)
  change_time_period_time_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[6,"change_time_period",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// check_time_until_logout => /users/sessions/check_time_until_logout(.:format)
  // function(options)
  check_time_until_logout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[6,"check_time_until_logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// complete_time_periods => /time_periods/complete(.:format)
  // function(options)
  complete_time_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[6,"complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_folder_administration_documents => /administration/documents/create_folder(.:format)
  // function(options)
  create_folder_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"create_folder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_folder_documents => /documents/create_folder(.:format)
  // function(options)
  create_folder_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"create_folder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_folder_form_administration_documents => /administration/documents/create_folder_form(.:format)
  // function(options)
  create_folder_form_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"create_folder_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_folder_form_documents => /documents/create_folder_form(.:format)
  // function(options)
  create_folder_form_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"create_folder_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_saml_session => /saml/sign_out(.:format)
  // function(options)
  destroy_saml_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// detach_administration_documents => /administration/documents/detach(.:format)
  // function(options)
  detach_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"detach",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// detach_documents => /documents/detach(.:format)
  // function(options)
  detach_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"detach",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// disable_indicator_justification_administration_account_risk_entity => /administration/accounts/:account_id/risk_entities/:id/disable_indicator_justification(.:format)
  // function(account_id, id, options)
  disable_indicator_justification_administration_account_risk_entity_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"disable_indicator_justification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// documentation_document => /documentation/:documentation_id/documents/:id(.:format)
  // function(documentation_id, id, options)
  documentation_document_path: Utils.route([["documentation_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documentation",false],[2,[7,"/",false],[2,[3,"documentation_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// download_administration_document => /administration/documents/:id/download(.:format)
  // function(id, options)
  download_administration_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// download_document => /documents/:id/download(.:format)
  // function(id, options)
  download_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_administration_account_risk_entity => /administration/accounts/:account_id/risk_entities/:id/edit(.:format)
  // function(account_id, id, options)
  edit_administration_account_risk_entity_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_administration_account_risk_entity_department_risk_entity_definition => /administration/accounts/:account_id/risk_entities/:risk_entity_id/department_risk_entity_definitions/:id/edit(.:format)
  // function(account_id, risk_entity_id, id, options)
  edit_administration_account_risk_entity_department_risk_entity_definition_path: Utils.route([["account_id",true],["risk_entity_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"department_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_administration_account_risk_entity_line_of_business_risk_entity_definition => /administration/accounts/:account_id/risk_entities/:risk_entity_id/line_of_business_risk_entity_definitions/:id/edit(.:format)
  // function(account_id, risk_entity_id, id, options)
  edit_administration_account_risk_entity_line_of_business_risk_entity_definition_path: Utils.route([["account_id",true],["risk_entity_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"line_of_business_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_administration_account_risk_entity_risk_entity_risk_category_settings => /administration/accounts/:account_id/risk_entities/:risk_entity_id/risk_entity_risk_category_settings/edit(.:format)
  // function(account_id, risk_entity_id, options)
  edit_administration_account_risk_entity_risk_entity_risk_category_settings_path: Utils.route([["account_id",true],["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"risk_entity_risk_category_settings",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_administration_document => /administration/documents/:id/edit(.:format)
  // function(id, options)
  edit_administration_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_complete_risk_category => /:time_period/risk_categories/:id/edit_complete(.:format)
  // function(time_period, id, options)
  edit_complete_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_document => /documents/:id/edit(.:format)
  // function(id, options)
  edit_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_link => /links/:id/edit(.:format)
  // function(id, options)
  edit_link_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_risk_appetite_risk_category => /:time_period/risk_categories/:id/edit_risk_appetite(.:format)
  // function(time_period, id, options)
  edit_risk_appetite_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_risk_appetite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_risk_category => /:time_period/risk_categories/:id/edit(.:format)
  // function(time_period, id, options)
  edit_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_risk_category_overview_risk_category => /:time_period/risk_categories/:id/edit_risk_category_overview(.:format)
  // function(time_period, id, options)
  edit_risk_category_overview_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_risk_category_overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_risk_component_weight_justification_risk_category => /:time_period/risk_categories/:id/edit_risk_component_weight_justification(.:format)
  // function(time_period, id, options)
  edit_risk_component_weight_justification_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_risk_component_weight_justification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_settings_overrides_assessment_overrides_self_assessment_consideration => /settings/overrides/assessment_overrides/self_assessment_consideration_risk_entity_definitions/:id/edit(.:format)
  // function(id, options)
  edit_settings_overrides_assessment_overrides_self_assessment_consideration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"assessment_overrides",false],[2,[7,"/",false],[2,[6,"self_assessment_consideration_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_settings_overrides_attribute_override_risk_attribute => /settings/overrides/attribute_overrides/:attribute_override_id/risk_attributes/:id/edit(.:format)
  // function(attribute_override_id, id, options)
  edit_settings_overrides_attribute_override_risk_attribute_path: Utils.route([["attribute_override_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"attribute_overrides",false],[2,[7,"/",false],[2,[3,"attribute_override_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_settings_overrides_data_input_section => /settings/overrides/data_input_sections/:id/edit(.:format)
  // function(id, options)
  edit_settings_overrides_data_input_section_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_settings_overrides_data_input_section_data_input_risk_entity_definition => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/:id/edit(.:format)
  // function(data_input_section_id, id, options)
  edit_settings_overrides_data_input_section_data_input_risk_entity_definition_path: Utils.route([["data_input_section_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_settings_overrides_indicator_overrides_risk_attribute_indicator => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/:id/edit(.:format)
  // function(id, options)
  edit_settings_overrides_indicator_overrides_risk_attribute_indicator_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_settings_overrides_risk_category_override => /settings/overrides/risk_category_overrides/:id/edit(.:format)
  // function(id, options)
  edit_settings_overrides_risk_category_override_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"risk_category_overrides",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_settings_overrides_risk_category_root_definition_override => /settings/overrides/risk_category_root_definition_overrides/:id/edit(.:format)
  // function(id, options)
  edit_settings_overrides_risk_category_root_definition_override_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"risk_category_root_definition_overrides",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_step_1_patch_risk_category => /:time_period/risk_categories/:id/edit_step_1_patch(.:format)
  // function(time_period, id, options)
  edit_step_1_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_1_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_step_1_risk_category => /:time_period/risk_categories/:id/edit_step_1(.:format)
  // function(time_period, id, options)
  edit_step_1_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_1",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_step_2_patch_risk_category => /:time_period/risk_categories/:id/edit_step_2_patch(.:format)
  // function(time_period, id, options)
  edit_step_2_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_2_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_step_2_risk_category => /:time_period/risk_categories/:id/edit_step_2(.:format)
  // function(time_period, id, options)
  edit_step_2_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_step_3_patch_risk_category => /:time_period/risk_categories/:id/edit_step_3_patch(.:format)
  // function(time_period, id, options)
  edit_step_3_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_3_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_step_3_risk_category => /:time_period/risk_categories/:id/edit_step_3(.:format)
  // function(time_period, id, options)
  edit_step_3_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_3",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_step_4_patch_risk_category => /:time_period/risk_categories/:id/edit_step_4_patch(.:format)
  // function(time_period, id, options)
  edit_step_4_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_4_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_step_4_risk_category => /:time_period/risk_categories/:id/edit_step_4(.:format)
  // function(time_period, id, options)
  edit_step_4_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_4",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_step_5_patch_risk_category => /:time_period/risk_categories/:id/edit_step_5_patch(.:format)
  // function(time_period, id, options)
  edit_step_5_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_5_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_step_5_risk_category => /:time_period/risk_categories/:id/edit_step_5(.:format)
  // function(time_period, id, options)
  edit_step_5_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_step_5",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_time_period => /time_periods/:id/edit(.:format)
  // function(id, options)
  edit_time_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// enable_indicator_justification_administration_account_risk_entity => /administration/accounts/:account_id/risk_entities/:id/enable_indicator_justification(.:format)
  // function(account_id, id, options)
  enable_indicator_justification_administration_account_risk_entity_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"enable_indicator_justification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// exception_saml_session => /saml/exception(.:format)
  // function(options)
  exception_saml_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"exception",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// extend_session => /users/sessions/extend_session(.:format)
  // function(options)
  extend_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[6,"extend_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// final_step_risk_category => /:time_period/risk_categories/:id/final_step(.:format)
  // function(time_period, id, options)
  final_step_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"final_step",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// grc_session => /grc_session(.:format)
  // function(options)
  grc_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"grc_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// idp_sign_out_saml_session => /saml/idp_sign_out(.:format)
  // function(options)
  idp_sign_out_saml_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"idp_sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// import_document => /documents/:id/import(.:format)
  // function(id, options)
  import_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// in_process_time_periods => /time_periods/in_process(.:format)
  // function(options)
  in_process_time_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[6,"in_process",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// legacy_upload_success_administration_documents => /administration/documents/legacy_upload_success(.:format)
  // function(options)
  legacy_upload_success_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"legacy_upload_success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// legacy_upload_success_documents => /documents/legacy_upload_success(.:format)
  // function(options)
  legacy_upload_success_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"legacy_upload_success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// link => /links/:id(.:format)
  // function(id, options)
  link_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// links => /links(.:format)
  // function(options)
  links_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// mark_status_risk_category => /:time_period/risk_categories/:id/mark_status(.:format)
  // function(time_period, id, options)
  mark_status_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// metadata_saml_session => /saml/metadata(.:format)
  // function(options)
  metadata_saml_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// modal_index_administration_documents => /administration/documents/modal_index(.:format)
  // function(options)
  modal_index_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"modal_index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// modal_index_documents => /documents/modal_index(.:format)
  // function(options)
  modal_index_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"modal_index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// modal_index_links => /links/modal_index(.:format)
  // function(options)
  modal_index_links_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[6,"modal_index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// months_time_periods => /time_periods/months(.:format)
  // function(options)
  months_time_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[6,"months",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// move_administration_documents => /administration/documents/move(.:format)
  // function(options)
  move_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// move_documents => /documents/move(.:format)
  // function(options)
  move_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// move_form_administration_documents => /administration/documents/move_form(.:format)
  // function(options)
  move_form_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"move_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// move_form_documents => /documents/move_form(.:format)
  // function(options)
  move_form_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"move_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// move_form_settings_overrides_data_input_section_data_input_risk_entity_definitions => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/move_form(.:format)
  // function(data_input_section_id, options)
  move_form_settings_overrides_data_input_section_data_input_risk_entity_definitions_path: Utils.route([["data_input_section_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"move_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// move_settings_overrides_data_input_section_data_input_risk_entity_definitions => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/move(.:format)
  // function(data_input_section_id, options)
  move_settings_overrides_data_input_section_data_input_risk_entity_definitions_path: Utils.route([["data_input_section_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_administration_account_risk_entity => /administration/accounts/:account_id/risk_entities/new(.:format)
  // function(account_id, options)
  new_administration_account_risk_entity_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_administration_account_risk_entity_department_risk_entity_definition => /administration/accounts/:account_id/risk_entities/:risk_entity_id/department_risk_entity_definitions/new(.:format)
  // function(account_id, risk_entity_id, options)
  new_administration_account_risk_entity_department_risk_entity_definition_path: Utils.route([["account_id",true],["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"department_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_administration_account_risk_entity_line_of_business_risk_entity_definition => /administration/accounts/:account_id/risk_entities/:risk_entity_id/line_of_business_risk_entity_definitions/new(.:format)
  // function(account_id, risk_entity_id, options)
  new_administration_account_risk_entity_line_of_business_risk_entity_definition_path: Utils.route([["account_id",true],["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"line_of_business_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_administration_account_risk_entity_sync_lobdep => /administration/accounts/:account_id/risk_entities/:risk_entity_id/sync_lobdep/new(.:format)
  // function(account_id, risk_entity_id, options)
  new_administration_account_risk_entity_sync_lobdep_path: Utils.route([["account_id",true],["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"sync_lobdep",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_administration_document => /administration/documents/new(.:format)
  // function(options)
  new_administration_document_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_document => /documents/new(.:format)
  // function(options)
  new_document_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_link => /links/new(.:format)
  // function(options)
  new_link_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_risk_category => /:time_period/risk_categories/new(.:format)
  // function(time_period, options)
  new_risk_category_path: Utils.route([["time_period",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_saml_session => /saml/sign_in(.:format)
  // function(options)
  new_saml_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_settings_overrides_assessment_overrides_self_assessment_consideration => /settings/overrides/assessment_overrides/self_assessment_consideration_risk_entity_definitions/new(.:format)
  // function(options)
  new_settings_overrides_assessment_overrides_self_assessment_consideration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"assessment_overrides",false],[2,[7,"/",false],[2,[6,"self_assessment_consideration_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_settings_overrides_attribute_override_risk_attribute => /settings/overrides/attribute_overrides/:attribute_override_id/risk_attributes/new(.:format)
  // function(attribute_override_id, options)
  new_settings_overrides_attribute_override_risk_attribute_path: Utils.route([["attribute_override_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"attribute_overrides",false],[2,[7,"/",false],[2,[3,"attribute_override_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_settings_overrides_data_input_section => /settings/overrides/data_input_sections/new(.:format)
  // function(options)
  new_settings_overrides_data_input_section_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_settings_overrides_data_input_section_data_input_risk_entity_definition => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/new(.:format)
  // function(data_input_section_id, options)
  new_settings_overrides_data_input_section_data_input_risk_entity_definition_path: Utils.route([["data_input_section_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_settings_overrides_indicator_overrides_risk_attribute_indicator => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/new(.:format)
  // function(options)
  new_settings_overrides_indicator_overrides_risk_attribute_indicator_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_time_period => /time_periods/new(.:format)
  // function(options)
  new_time_period_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// not_found_time_periods => /time_periods/not_found(.:format)
  // function(options)
  not_found_time_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[6,"not_found",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// quarters_time_periods => /time_periods/quarters(.:format)
  // function(options)
  quarters_time_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[6,"quarters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rcsa_import_document => /documents/:id/rcsa_import(.:format)
  // function(id, options)
  rcsa_import_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"rcsa_import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rename_administration_document => /administration/documents/:id/rename(.:format)
  // function(id, options)
  rename_administration_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rename_document => /documents/:id/rename(.:format)
  // function(id, options)
  rename_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rename_form_administration_document => /administration/documents/:id/rename_form(.:format)
  // function(id, options)
  rename_form_administration_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"rename_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rename_form_document => /documents/:id/rename_form(.:format)
  // function(id, options)
  rename_form_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"rename_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rename_form_settings_overrides_data_input_section_data_input_risk_entity_definitions => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/rename_form(.:format)
  // function(data_input_section_id, options)
  rename_form_settings_overrides_data_input_section_data_input_risk_entity_definitions_path: Utils.route([["data_input_section_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"rename_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rename_settings_overrides_data_input_section_data_input_risk_entity_definitions => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/rename(.:format)
  // function(data_input_section_id, options)
  rename_settings_overrides_data_input_section_data_input_risk_entity_definitions_path: Utils.route([["data_input_section_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"rename",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// risk_attribute_rating_descriptions_administration_account_risk_entity => /administration/accounts/:account_id/risk_entities/:id/risk_attribute_rating_descriptions(.:format)
  // function(account_id, id, options)
  risk_attribute_rating_descriptions_administration_account_risk_entity_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"risk_attribute_rating_descriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// risk_attributes_risk_category => /:time_period/risk_categories/:id/risk_attributes(.:format)
  // function(time_period, id, options)
  risk_attributes_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// risk_profile_risk_categories => /risk_profile/risk_categories(.:format)
  // function(options)
  risk_profile_risk_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"risk_profile",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// saml_session => /saml/auth(.:format)
  // function(options)
  saml_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// search_risk_entities => /risk_entities/search(.:format)
  // function(options)
  search_risk_entities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// search_settings_overrides_data_input_sections => /settings/overrides/data_input_sections/search(.:format)
  // function(options)
  search_settings_overrides_data_input_sections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// set_primary_administration_account_risk_entity => /administration/accounts/:account_id/risk_entities/:id/set_primary(.:format)
  // function(account_id, id, options)
  set_primary_administration_account_risk_entity_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"set_primary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_call_report_import => /settings/call_reports/:call_report_id/import(.:format)
  // function(call_report_id, options)
  settings_call_report_import_path: Utils.route([["call_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"call_reports",false],[2,[7,"/",false],[2,[3,"call_report_id",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_call_report_import_finished => /settings/call_reports/:call_report_id/import_finished(.:format)
  // function(call_report_id, options)
  settings_call_report_import_finished_path: Utils.route([["call_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"call_reports",false],[2,[7,"/",false],[2,[3,"call_report_id",false],[2,[7,"/",false],[2,[6,"import_finished",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_call_report_import_status => /settings/call_reports/:call_report_id/import_status(.:format)
  // function(call_report_id, options)
  settings_call_report_import_status_path: Utils.route([["call_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"call_reports",false],[2,[7,"/",false],[2,[3,"call_report_id",false],[2,[7,"/",false],[2,[6,"import_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_call_report_sync => /settings/call_reports/:call_report_id/sync(.:format)
  // function(call_report_id, options)
  settings_call_report_sync_path: Utils.route([["call_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"call_reports",false],[2,[7,"/",false],[2,[3,"call_report_id",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_call_report_sync_finished => /settings/call_reports/:call_report_id/sync_finished(.:format)
  // function(call_report_id, options)
  settings_call_report_sync_finished_path: Utils.route([["call_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"call_reports",false],[2,[7,"/",false],[2,[3,"call_report_id",false],[2,[7,"/",false],[2,[6,"sync_finished",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_call_report_sync_status => /settings/call_reports/:call_report_id/sync_status(.:format)
  // function(call_report_id, options)
  settings_call_report_sync_status_path: Utils.route([["call_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"call_reports",false],[2,[7,"/",false],[2,[3,"call_report_id",false],[2,[7,"/",false],[2,[6,"sync_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_data_integration_sync => /settings/data_integrations/:data_integration_id/sync(.:format)
  // function(data_integration_id, options)
  settings_data_integration_sync_path: Utils.route([["data_integration_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"data_integrations",false],[2,[7,"/",false],[2,[3,"data_integration_id",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_data_integration_sync_finished => /settings/data_integrations/:data_integration_id/sync_finished(.:format)
  // function(data_integration_id, options)
  settings_data_integration_sync_finished_path: Utils.route([["data_integration_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"data_integrations",false],[2,[7,"/",false],[2,[3,"data_integration_id",false],[2,[7,"/",false],[2,[6,"sync_finished",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_data_integration_sync_status => /settings/data_integrations/:data_integration_id/sync_status(.:format)
  // function(data_integration_id, options)
  settings_data_integration_sync_status_path: Utils.route([["data_integration_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"data_integrations",false],[2,[7,"/",false],[2,[3,"data_integration_id",false],[2,[7,"/",false],[2,[6,"sync_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_data_repository_source_import => /settings/data_repository_sources/:data_repository_source_id/import(.:format)
  // function(data_repository_source_id, options)
  settings_data_repository_source_import_path: Utils.route([["data_repository_source_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"data_repository_sources",false],[2,[7,"/",false],[2,[3,"data_repository_source_id",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_data_repository_source_import_status => /settings/data_repository_sources/:data_repository_source_id/import_status(.:format)
  // function(data_repository_source_id, options)
  settings_data_repository_source_import_status_path: Utils.route([["data_repository_source_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"data_repository_sources",false],[2,[7,"/",false],[2,[3,"data_repository_source_id",false],[2,[7,"/",false],[2,[6,"import_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_data_repository_source_sync => /settings/data_repository_sources/:data_repository_source_id/sync(.:format)
  // function(data_repository_source_id, options)
  settings_data_repository_source_sync_path: Utils.route([["data_repository_source_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"data_repository_sources",false],[2,[7,"/",false],[2,[3,"data_repository_source_id",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_data_repository_source_sync_status => /settings/data_repository_sources/:data_repository_source_id/sync_status(.:format)
  // function(data_repository_source_id, options)
  settings_data_repository_source_sync_status_path: Utils.route([["data_repository_source_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"data_repository_sources",false],[2,[7,"/",false],[2,[3,"data_repository_source_id",false],[2,[7,"/",false],[2,[6,"sync_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_ncua_report_import => /settings/ncua_reports/:ncua_report_id/import(.:format)
  // function(ncua_report_id, options)
  settings_ncua_report_import_path: Utils.route([["ncua_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"ncua_reports",false],[2,[7,"/",false],[2,[3,"ncua_report_id",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_ncua_report_import_status => /settings/ncua_reports/:ncua_report_id/import_status(.:format)
  // function(ncua_report_id, options)
  settings_ncua_report_import_status_path: Utils.route([["ncua_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"ncua_reports",false],[2,[7,"/",false],[2,[3,"ncua_report_id",false],[2,[7,"/",false],[2,[6,"import_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_ncua_report_sync => /settings/ncua_reports/:ncua_report_id/sync(.:format)
  // function(ncua_report_id, options)
  settings_ncua_report_sync_path: Utils.route([["ncua_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"ncua_reports",false],[2,[7,"/",false],[2,[3,"ncua_report_id",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_ncua_report_sync_status => /settings/ncua_reports/:ncua_report_id/sync_status(.:format)
  // function(ncua_report_id, options)
  settings_ncua_report_sync_status_path: Utils.route([["ncua_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"ncua_reports",false],[2,[7,"/",false],[2,[3,"ncua_report_id",false],[2,[7,"/",false],[2,[6,"sync_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_overrides_assessment_overrides_risk_attributes => /settings/overrides/assessment_overrides/category/:category_definition_id(.:format)
  // function(category_definition_id, options)
  settings_overrides_assessment_overrides_risk_attributes_path: Utils.route([["category_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"assessment_overrides",false],[2,[7,"/",false],[2,[6,"category",false],[2,[7,"/",false],[2,[3,"category_definition_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_overrides_assessment_overrides_self_assessment_consideration => /settings/overrides/assessment_overrides/self_assessment_consideration_risk_entity_definitions/:id(.:format)
  // function(id, options)
  settings_overrides_assessment_overrides_self_assessment_consideration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"assessment_overrides",false],[2,[7,"/",false],[2,[6,"self_assessment_consideration_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_overrides_assessment_overrides_self_assessment_consideration_activate => /settings/overrides/assessment_overrides/self_assessment_consideration_risk_entity_definitions/:self_assessment_consideration_id/activate(.:format)
  // function(self_assessment_consideration_id, options)
  settings_overrides_assessment_overrides_self_assessment_consideration_activate_path: Utils.route([["self_assessment_consideration_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"assessment_overrides",false],[2,[7,"/",false],[2,[6,"self_assessment_consideration_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"self_assessment_consideration_id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_overrides_assessment_overrides_self_assessment_consideration_deactivate => /settings/overrides/assessment_overrides/self_assessment_consideration_risk_entity_definitions/:self_assessment_consideration_id/deactivate(.:format)
  // function(self_assessment_consideration_id, options)
  settings_overrides_assessment_overrides_self_assessment_consideration_deactivate_path: Utils.route([["self_assessment_consideration_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"assessment_overrides",false],[2,[7,"/",false],[2,[6,"self_assessment_consideration_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"self_assessment_consideration_id",false],[2,[7,"/",false],[2,[6,"deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_overrides_assessment_overrides_self_assessment_considerations => /settings/overrides/assessment_overrides/self_assessment_consideration_risk_entity_definitions(.:format)
  // function(options)
  settings_overrides_assessment_overrides_self_assessment_considerations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"assessment_overrides",false],[2,[7,"/",false],[2,[6,"self_assessment_consideration_risk_entity_definitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_overrides_attribute_override_risk_attribute => /settings/overrides/attribute_overrides/:attribute_override_id/risk_attributes/:id(.:format)
  // function(attribute_override_id, id, options)
  settings_overrides_attribute_override_risk_attribute_path: Utils.route([["attribute_override_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"attribute_overrides",false],[2,[7,"/",false],[2,[3,"attribute_override_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_overrides_attribute_override_risk_attribute_activate => /settings/overrides/attribute_overrides/:attribute_override_id/risk_attributes/:risk_attribute_id/activate(.:format)
  // function(attribute_override_id, risk_attribute_id, options)
  settings_overrides_attribute_override_risk_attribute_activate_path: Utils.route([["attribute_override_id",true],["risk_attribute_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"attribute_overrides",false],[2,[7,"/",false],[2,[3,"attribute_override_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[3,"risk_attribute_id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// settings_overrides_attribute_override_risk_attribute_deactivate => /settings/overrides/attribute_overrides/:attribute_override_id/risk_attributes/:risk_attribute_id/deactivate(.:format)
  // function(attribute_override_id, risk_attribute_id, options)
  settings_overrides_attribute_override_risk_attribute_deactivate_path: Utils.route([["attribute_override_id",true],["risk_attribute_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"attribute_overrides",false],[2,[7,"/",false],[2,[3,"attribute_override_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[3,"risk_attribute_id",false],[2,[7,"/",false],[2,[6,"deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// settings_overrides_attribute_override_risk_attribute_domains => /settings/overrides/attribute_overrides/:attribute_override_id/risk_attribute_domains(.:format)
  // function(attribute_override_id, options)
  settings_overrides_attribute_override_risk_attribute_domains_path: Utils.route([["attribute_override_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"attribute_overrides",false],[2,[7,"/",false],[2,[3,"attribute_override_id",false],[2,[7,"/",false],[2,[6,"risk_attribute_domains",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_overrides_attribute_override_risk_attributes => /settings/overrides/attribute_overrides/:attribute_override_id/risk_attributes(.:format)
  // function(attribute_override_id, options)
  settings_overrides_attribute_override_risk_attributes_path: Utils.route([["attribute_override_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"attribute_overrides",false],[2,[7,"/",false],[2,[3,"attribute_override_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_overrides_data_input_section => /settings/overrides/data_input_sections/:id(.:format)
  // function(id, options)
  settings_overrides_data_input_section_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_overrides_data_input_section_data_input_risk_entity_definition => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/:id(.:format)
  // function(data_input_section_id, id, options)
  settings_overrides_data_input_section_data_input_risk_entity_definition_path: Utils.route([["data_input_section_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_overrides_data_input_section_data_input_risk_entity_definition_promote_to_global_library => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/:data_input_risk_entity_definition_id/promote_to_global_library(.:format)
  // function(data_input_section_id, data_input_risk_entity_definition_id, options)
  settings_overrides_data_input_section_data_input_risk_entity_definition_promote_to_global_library_path: Utils.route([["data_input_section_id",true],["data_input_risk_entity_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"data_input_risk_entity_definition_id",false],[2,[7,"/",false],[2,[6,"promote_to_global_library",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// settings_overrides_data_input_section_data_input_risk_entity_definition_remove => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/:data_input_risk_entity_definition_id/remove(.:format)
  // function(data_input_section_id, data_input_risk_entity_definition_id, options)
  settings_overrides_data_input_section_data_input_risk_entity_definition_remove_path: Utils.route([["data_input_section_id",true],["data_input_risk_entity_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"data_input_risk_entity_definition_id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// settings_overrides_data_input_section_data_input_risk_entity_definitions => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions(.:format)
  // function(data_input_section_id, options)
  settings_overrides_data_input_section_data_input_risk_entity_definitions_path: Utils.route([["data_input_section_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_overrides_data_input_sections => /settings/overrides/data_input_sections(.:format)
  // function(options)
  settings_overrides_data_input_sections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_overrides_indicator_overrides_data_input_risk_entity_definitions => /settings/overrides/indicator_overrides/data_input_risk_entity_definitions(.:format)
  // function(options)
  settings_overrides_indicator_overrides_data_input_risk_entity_definitions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_overrides_indicator_overrides_risk_attribute_indicator => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/:id(.:format)
  // function(id, options)
  settings_overrides_indicator_overrides_risk_attribute_indicator_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_overrides_indicator_overrides_risk_attribute_indicator_activate => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/:risk_attribute_indicator_id/activate(.:format)
  // function(risk_attribute_indicator_id, options)
  settings_overrides_indicator_overrides_risk_attribute_indicator_activate_path: Utils.route([["risk_attribute_indicator_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"risk_attribute_indicator_id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_overrides_indicator_overrides_risk_attribute_indicator_deactivate => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/:risk_attribute_indicator_id/deactivate(.:format)
  // function(risk_attribute_indicator_id, options)
  settings_overrides_indicator_overrides_risk_attribute_indicator_deactivate_path: Utils.route([["risk_attribute_indicator_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"risk_attribute_indicator_id",false],[2,[7,"/",false],[2,[6,"deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_overrides_indicator_overrides_risk_attribute_indicator_modal_risk_tags => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/:risk_attribute_indicator_id/modal_risk_tags(.:format)
  // function(risk_attribute_indicator_id, options)
  settings_overrides_indicator_overrides_risk_attribute_indicator_modal_risk_tags_path: Utils.route([["risk_attribute_indicator_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"risk_attribute_indicator_id",false],[2,[7,"/",false],[2,[6,"modal_risk_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_overrides_indicator_overrides_risk_attribute_indicator_promote_to_global_library => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/:risk_attribute_indicator_id/promote_to_global_library(.:format)
  // function(risk_attribute_indicator_id, options)
  settings_overrides_indicator_overrides_risk_attribute_indicator_promote_to_global_library_path: Utils.route([["risk_attribute_indicator_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"risk_attribute_indicator_id",false],[2,[7,"/",false],[2,[6,"promote_to_global_library",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_overrides_indicator_overrides_risk_attribute_indicator_risk_tags_update => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/:risk_attribute_indicator_id/risk_tags_update(.:format)
  // function(risk_attribute_indicator_id, options)
  settings_overrides_indicator_overrides_risk_attribute_indicator_risk_tags_update_path: Utils.route([["risk_attribute_indicator_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[2,[7,"/",false],[2,[3,"risk_attribute_indicator_id",false],[2,[7,"/",false],[2,[6,"risk_tags_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// settings_overrides_indicator_overrides_risk_attribute_indicators => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions(.:format)
  // function(options)
  settings_overrides_indicator_overrides_risk_attribute_indicators_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_overrides_indicator_overrides_risk_attributes => /settings/overrides/indicator_overrides/category/:category_definition_id(.:format)
  // function(category_definition_id, options)
  settings_overrides_indicator_overrides_risk_attributes_path: Utils.route([["category_definition_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"category",false],[2,[7,"/",false],[2,[3,"category_definition_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// settings_overrides_indicator_overrides_risk_entities => /settings/overrides/indicator_overrides/risk_entities(.:format)
  // function(options)
  settings_overrides_indicator_overrides_risk_entities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_overrides_risk_category_override => /settings/overrides/risk_category_overrides/:id(.:format)
  // function(id, options)
  settings_overrides_risk_category_override_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"risk_category_overrides",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_overrides_risk_category_overrides => /settings/overrides/risk_category_overrides(.:format)
  // function(options)
  settings_overrides_risk_category_overrides_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"risk_category_overrides",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_overrides_risk_category_root_definition_override => /settings/overrides/risk_category_root_definition_overrides/:id(.:format)
  // function(id, options)
  settings_overrides_risk_category_root_definition_override_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"risk_category_root_definition_overrides",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_overrides_risk_category_root_definition_overrides => /settings/overrides/risk_category_root_definition_overrides(.:format)
  // function(options)
  settings_overrides_risk_category_root_definition_overrides_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"risk_category_root_definition_overrides",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_snowflake_data_source_sync => /settings/snowflake_data_sources/:snowflake_data_source_id/sync(.:format)
  // function(snowflake_data_source_id, options)
  settings_snowflake_data_source_sync_path: Utils.route([["snowflake_data_source_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"snowflake_data_sources",false],[2,[7,"/",false],[2,[3,"snowflake_data_source_id",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_snowflake_data_source_sync_status => /settings/snowflake_data_sources/:snowflake_data_source_id/sync_status(.:format)
  // function(snowflake_data_source_id, options)
  settings_snowflake_data_source_sync_status_path: Utils.route([["snowflake_data_source_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"snowflake_data_sources",false],[2,[7,"/",false],[2,[3,"snowflake_data_source_id",false],[2,[7,"/",false],[2,[6,"sync_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_y9c_report_sync => /settings/y9c_reports/:y9c_report_id/sync(.:format)
  // function(y9c_report_id, options)
  settings_y9c_report_sync_path: Utils.route([["y9c_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"y9c_reports",false],[2,[7,"/",false],[2,[3,"y9c_report_id",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings_y9c_report_sync_status => /settings/y9c_reports/:y9c_report_id/sync_status(.:format)
  // function(y9c_report_id, options)
  settings_y9c_report_sync_status_path: Utils.route([["y9c_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"y9c_reports",false],[2,[7,"/",false],[2,[3,"y9c_report_id",false],[2,[7,"/",false],[2,[6,"sync_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sign_upload_administration_documents => /administration/documents/sign_upload(.:format)
  // function(options)
  sign_upload_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"sign_upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sign_upload_documents => /documents/sign_upload(.:format)
  // function(options)
  sign_upload_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"sign_upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// simplified_edit_form_risk_category => /:time_period/risk_categories/:id/simplified_edit_form(.:format)
  // function(time_period, id, options)
  simplified_edit_form_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"simplified_edit_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// simplified_edit_form_update_risk_category => /:time_period/risk_categories/:id/simplified_edit_form_update(.:format)
  // function(time_period, id, options)
  simplified_edit_form_update_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"simplified_edit_form_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// simplified_new_form_risk_categories => /:time_period/risk_categories/simplified_new_form(.:format)
  // function(time_period, options)
  simplified_new_form_risk_categories_path: Utils.route([["time_period",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[6,"simplified_new_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// simplified_new_form_update_risk_category => /:time_period/risk_categories/:id/simplified_new_form_update(.:format)
  // function(time_period, id, options)
  simplified_new_form_update_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"simplified_new_form_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_1_risk_categories => /:time_period/risk_categories/step_1(.:format)
  // function(time_period, options)
  step_1_risk_categories_path: Utils.route([["time_period",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[6,"step_1",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// step_2_post_risk_categories => /:time_period/risk_categories/step_2_post(.:format)
  // function(time_period, options)
  step_2_post_risk_categories_path: Utils.route([["time_period",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[6,"step_2_post",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// step_2_risk_categories => /:time_period/risk_categories/step_2(.:format)
  // function(time_period, options)
  step_2_risk_categories_path: Utils.route([["time_period",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[6,"step_2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// step_3_patch_risk_category => /:time_period/risk_categories/:id/step_3_patch(.:format)
  // function(time_period, id, options)
  step_3_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_3_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_3_risk_category => /:time_period/risk_categories/:id/step_3(.:format)
  // function(time_period, id, options)
  step_3_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_3",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_4_patch_risk_category => /:time_period/risk_categories/:id/step_4_patch(.:format)
  // function(time_period, id, options)
  step_4_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_4_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_4_risk_category => /:time_period/risk_categories/:id/step_4(.:format)
  // function(time_period, id, options)
  step_4_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_4",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_5_patch_risk_category => /:time_period/risk_categories/:id/step_5_patch(.:format)
  // function(time_period, id, options)
  step_5_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_5_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_5_risk_category => /:time_period/risk_categories/:id/step_5(.:format)
  // function(time_period, id, options)
  step_5_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_5",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_6_patch_risk_category => /:time_period/risk_categories/:id/step_6_patch(.:format)
  // function(time_period, id, options)
  step_6_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_6_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_6_risk_category => /:time_period/risk_categories/:id/step_6(.:format)
  // function(time_period, id, options)
  step_6_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_6",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_7_patch_risk_category => /:time_period/risk_categories/:id/step_7_patch(.:format)
  // function(time_period, id, options)
  step_7_patch_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_7_patch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// step_7_risk_category => /:time_period/risk_categories/:id/step_7(.:format)
  // function(time_period, id, options)
  step_7_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_7",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// strategize => /users/sessions/strategize(.:format)
  // function(options)
  strategize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[6,"strategize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sync_fintech_assessments_documents => /documents/sync_fintech_assessments(.:format)
  // function(options)
  sync_fintech_assessments_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"sync_fintech_assessments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// time_period => /time_periods/:id(.:format)
  // function(id, options)
  time_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// time_period_consolidated_export => /time_periods/:time_period_id/consolidated_export(.:format)
  // function(time_period_id, options)
  time_period_consolidated_export_path: Utils.route([["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"consolidated_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_period_edit_composite_risk_overview => /time_periods/:time_period_id/edit_composite_risk_overview(.:format)
  // function(time_period_id, options)
  time_period_edit_composite_risk_overview_path: Utils.route([["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"edit_composite_risk_overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_period_export => /time_periods/:time_period_id/export(.:format)
  // function(time_period_id, options)
  time_period_export_path: Utils.route([["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_period_previous_time_periods => /time_periods/:time_period_id/previous_time_periods(.:format)
  // function(time_period_id, options)
  time_period_previous_time_periods_path: Utils.route([["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"previous_time_periods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_period_risk_attributes => /time_periods/:time_period_id/risk_attributes(.:format)
  // function(time_period_id, options)
  time_period_risk_attributes_path: Utils.route([["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_period_risk_categories => /time_periods/:time_period_id/risk_categories(.:format)
  // function(time_period_id, options)
  time_period_risk_categories_path: Utils.route([["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_period_status => /time_periods/:time_period_id/status(.:format)
  // function(time_period_id, options)
  time_period_status_path: Utils.route([["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_period_update_composite_risk_overview => /time_periods/:time_period_id/update_composite_risk_overview(.:format)
  // function(time_period_id, options)
  time_period_update_composite_risk_overview_path: Utils.route([["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"update_composite_risk_overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_period_update_using_fiserv_gl_data_setting => /time_periods/:time_period_id/update_using_fiserv_gl_data_setting(.:format)
  // function(time_period_id, options)
  time_period_update_using_fiserv_gl_data_setting_path: Utils.route([["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"update_using_fiserv_gl_data_setting",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_periods => /time_periods(.:format)
  // function(options)
  time_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_periods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// toggle_pin_administration_document => /administration/documents/:id/toggle_pin(.:format)
  // function(id, options)
  toggle_pin_administration_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"toggle_pin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// toggle_pin_document => /documents/:id/toggle_pin(.:format)
  // function(id, options)
  toggle_pin_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"toggle_pin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_inherent_risk_component_risk_category => /:time_period/risk_categories/:id/update_inherent_risk_component(.:format)
  // function(time_period, id, options)
  update_inherent_risk_component_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_inherent_risk_component",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_order_administration_account_risk_entity_department_risk_entity_definitions => /administration/accounts/:account_id/risk_entities/:risk_entity_id/department_risk_entity_definitions/update_order(.:format)
  // function(account_id, risk_entity_id, options)
  update_order_administration_account_risk_entity_department_risk_entity_definitions_path: Utils.route([["account_id",true],["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"department_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"update_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// update_order_settings_overrides_assessment_overrides_self_assessment_considerations => /settings/overrides/assessment_overrides/self_assessment_consideration_risk_entity_definitions/update_order(.:format)
  // function(options)
  update_order_settings_overrides_assessment_overrides_self_assessment_considerations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"assessment_overrides",false],[2,[7,"/",false],[2,[6,"self_assessment_consideration_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"update_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_order_settings_overrides_attribute_override_risk_attributes => /settings/overrides/attribute_overrides/:attribute_override_id/risk_attributes/update_order(.:format)
  // function(attribute_override_id, options)
  update_order_settings_overrides_attribute_override_risk_attributes_path: Utils.route([["attribute_override_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"attribute_overrides",false],[2,[7,"/",false],[2,[3,"attribute_override_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[6,"update_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// update_order_settings_overrides_data_input_section_data_input_risk_entity_definitions => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/update_order(.:format)
  // function(data_input_section_id, options)
  update_order_settings_overrides_data_input_section_data_input_risk_entity_definitions_path: Utils.route([["data_input_section_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"update_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// update_order_settings_overrides_data_input_sections => /settings/overrides/data_input_sections/update_order(.:format)
  // function(options)
  update_order_settings_overrides_data_input_sections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[6,"update_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_order_settings_overrides_indicator_overrides_risk_attribute_indicators => /settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/update_order(.:format)
  // function(options)
  update_order_settings_overrides_indicator_overrides_risk_attribute_indicators_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"indicator_overrides",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicator_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"update_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_order_settings_overrides_risk_category_overrides => /settings/overrides/risk_category_overrides/update_order(.:format)
  // function(options)
  update_order_settings_overrides_risk_category_overrides_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"risk_category_overrides",false],[2,[7,"/",false],[2,[6,"update_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_order_settings_overrides_risk_category_root_definition_overrides => /settings/overrides/risk_category_root_definition_overrides/update_order(.:format)
  // function(options)
  update_order_settings_overrides_risk_category_root_definition_overrides_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"risk_category_root_definition_overrides",false],[2,[7,"/",false],[2,[6,"update_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_quality_risk_component_risk_category => /:time_period/risk_categories/:id/update_quality_risk_component(.:format)
  // function(time_period, id, options)
  update_quality_risk_component_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_quality_risk_component",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_risk_appetite_risk_category => /:time_period/risk_categories/:id/update_risk_appetite(.:format)
  // function(time_period, id, options)
  update_risk_appetite_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_risk_appetite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_risk_category_overview_risk_category => /:time_period/risk_categories/:id/update_risk_category_overview(.:format)
  // function(time_period, id, options)
  update_risk_category_overview_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_risk_category_overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_risk_component_weight_justification_risk_category => /:time_period/risk_categories/:id/update_risk_component_weight_justification(.:format)
  // function(time_period, id, options)
  update_risk_component_weight_justification_risk_category_path: Utils.route([["time_period",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"time_period",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_risk_component_weight_justification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_visible_settings_overrides_data_input_section_data_input_risk_entity_definitions => /settings/overrides/data_input_sections/:data_input_section_id/data_input_risk_entity_definitions/update_visible(.:format)
  // function(data_input_section_id, options)
  update_visible_settings_overrides_data_input_section_data_input_risk_entity_definitions_path: Utils.route([["data_input_section_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[3,"data_input_section_id",false],[2,[7,"/",false],[2,[6,"data_input_risk_entity_definitions",false],[2,[7,"/",false],[2,[6,"update_visible",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// update_visible_settings_overrides_data_input_sections => /settings/overrides/data_input_sections/update_visible(.:format)
  // function(options)
  update_visible_settings_overrides_data_input_sections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"overrides",false],[2,[7,"/",false],[2,[6,"data_input_sections",false],[2,[7,"/",false],[2,[6,"update_visible",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// upload_success_administration_documents => /administration/documents/upload_success(.:format)
  // function(options)
  upload_success_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"upload_success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// upload_success_documents => /documents/upload_success(.:format)
  // function(options)
  upload_success_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"upload_success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v1_risk_entities => /v1/risk_entities(.:format)
  // function(options)
  v1_risk_entities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v1_risk_entity_time_period => /v1/risk_entities/:risk_entity_id/time_periods/:id(.:format)
  // function(risk_entity_id, id, options)
  v1_risk_entity_time_period_path: Utils.route([["risk_entity_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// v1_risk_entity_time_period_data_input => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/data_inputs/:id(.:format)
  // function(risk_entity_id, time_period_id, id, options)
  v1_risk_entity_time_period_data_input_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"data_inputs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// v1_risk_entity_time_period_data_inputs => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/data_inputs(.:format)
  // function(risk_entity_id, time_period_id, options)
  v1_risk_entity_time_period_data_inputs_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"data_inputs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// v1_risk_entity_time_period_export_index => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/export(.:format)
  // function(risk_entity_id, time_period_id, options)
  v1_risk_entity_time_period_export_index_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// v1_risk_entity_time_period_import_index => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/import(.:format)
  // function(risk_entity_id, time_period_id, options)
  v1_risk_entity_time_period_import_index_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_attribute_indicator => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_attribute_indicators/:id(.:format)
  // function(risk_entity_id, time_period_id, id, options)
  v1_risk_entity_time_period_risk_attribute_indicator_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicators",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_attribute_indicators => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_attribute_indicators(.:format)
  // function(risk_entity_id, time_period_id, options)
  v1_risk_entity_time_period_risk_attribute_indicators_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicators",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_categories => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_categories(.:format)
  // function(risk_entity_id, time_period_id, options)
  v1_risk_entity_time_period_risk_categories_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_category => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_categories/:id(.:format)
  // function(risk_entity_id, time_period_id, id, options)
  v1_risk_entity_time_period_risk_category_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_category_risk_component => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_categories/:risk_category_id/risk_components/:id(.:format)
  // function(risk_entity_id, time_period_id, risk_category_id, id, options)
  v1_risk_entity_time_period_risk_category_risk_component_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["risk_category_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"risk_category_id",false],[2,[7,"/",false],[2,[6,"risk_components",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_category_risk_component_risk_attribute => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_categories/:risk_category_id/risk_components/:risk_component_id/risk_attributes/:id(.:format)
  // function(risk_entity_id, time_period_id, risk_category_id, risk_component_id, id, options)
  v1_risk_entity_time_period_risk_category_risk_component_risk_attribute_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["risk_category_id",true],["risk_component_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"risk_category_id",false],[2,[7,"/",false],[2,[6,"risk_components",false],[2,[7,"/",false],[2,[3,"risk_component_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_category_risk_component_risk_attribute_risk_attribute_indicator => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_categories/:risk_category_id/risk_components/:risk_component_id/risk_attributes/:risk_attribute_id/risk_attribute_indicators/:id(.:format)
  // function(risk_entity_id, time_period_id, risk_category_id, risk_component_id, risk_attribute_id, id, options)
  v1_risk_entity_time_period_risk_category_risk_component_risk_attribute_risk_attribute_indicator_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["risk_category_id",true],["risk_component_id",true],["risk_attribute_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"risk_category_id",false],[2,[7,"/",false],[2,[6,"risk_components",false],[2,[7,"/",false],[2,[3,"risk_component_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[3,"risk_attribute_id",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicators",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_category_risk_component_risk_attribute_risk_attribute_indicators => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_categories/:risk_category_id/risk_components/:risk_component_id/risk_attributes/:risk_attribute_id/risk_attribute_indicators(.:format)
  // function(risk_entity_id, time_period_id, risk_category_id, risk_component_id, risk_attribute_id, options)
  v1_risk_entity_time_period_risk_category_risk_component_risk_attribute_risk_attribute_indicators_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["risk_category_id",true],["risk_component_id",true],["risk_attribute_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"risk_category_id",false],[2,[7,"/",false],[2,[6,"risk_components",false],[2,[7,"/",false],[2,[3,"risk_component_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[2,[7,"/",false],[2,[3,"risk_attribute_id",false],[2,[7,"/",false],[2,[6,"risk_attribute_indicators",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_category_risk_component_risk_attributes => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_categories/:risk_category_id/risk_components/:risk_component_id/risk_attributes(.:format)
  // function(risk_entity_id, time_period_id, risk_category_id, risk_component_id, options)
  v1_risk_entity_time_period_risk_category_risk_component_risk_attributes_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["risk_category_id",true],["risk_component_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"risk_category_id",false],[2,[7,"/",false],[2,[6,"risk_components",false],[2,[7,"/",false],[2,[3,"risk_component_id",false],[2,[7,"/",false],[2,[6,"risk_attributes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]]]),
// v1_risk_entity_time_period_risk_category_risk_components => /v1/risk_entities/:risk_entity_id/time_periods/:time_period_id/risk_categories/:risk_category_id/risk_components(.:format)
  // function(risk_entity_id, time_period_id, risk_category_id, options)
  v1_risk_entity_time_period_risk_category_risk_components_path: Utils.route([["risk_entity_id",true],["time_period_id",true],["risk_category_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[2,[7,"/",false],[2,[3,"time_period_id",false],[2,[7,"/",false],[2,[6,"risk_categories",false],[2,[7,"/",false],[2,[3,"risk_category_id",false],[2,[7,"/",false],[2,[6,"risk_components",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// v1_risk_entity_time_periods => /v1/risk_entities/:risk_entity_id/time_periods(.:format)
  // function(risk_entity_id, options)
  v1_risk_entity_time_periods_path: Utils.route([["risk_entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"risk_entities",false],[2,[7,"/",false],[2,[3,"risk_entity_id",false],[2,[7,"/",false],[2,[6,"time_periods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// validate_file_administration_documents => /administration/documents/validate_file(.:format)
  // function(options)
  validate_file_administration_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administration",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"validate_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// validate_file_documents => /documents/validate_file(.:format)
  // function(options)
  validate_file_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"validate_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, "Routes", result);
  }

  return result;

}).call(this);

