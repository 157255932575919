import React from 'react';
import PropTypes from 'prop-types';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';

class TimePeriodDataTableRow extends React.Component {
  // This needs to be called in order for the selected keys to update
  componentDidMount() {
    if (this.props.isDepartmentRequired) {
      this.props.onSelectedChange(this.props.record.id, !this.props.isDepartmentRequired);
    }
  }

  render() {
    const record = this.props.record;
    const deptRequired = this.props.isDepartmentRequired;
    return (
      <tr>
        <td className='checkbox pull-left'>
          <label className={`checkbox-custom ${this.props.selected ? 'checked' : ''}`}>
            <input id={record.param}
              className='boolean'
              type='checkbox'
              checked={this.props.selected}
              onChange={this.handleCheckedChange}
              disabled={this.props.isDisabled} />
          </label>
        </td>
        <td className='pull-left'>
          {record.name} {this.props.isRating && ' Ratings'}
          {deptRequired &&
            <QuestionInfoTooltip
              content={`Cannot roll forward from previous time period due to department requirements changing. This ${window.riskEntitySettings.risk_category_label} must be created after time period is added.`}
              html={false}
              placement='bottom'
              trigger='hover'
            />}
        </td>
      </tr>
    );
  }

  handleCheckedChange = (e) => {
    this.props.onSelectedChange(this.props.record.id, e.target.checked);
  };
}

TimePeriodDataTableRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    name: PropTypes.string,
    param: PropTypes.string,
  }),
  selected: PropTypes.bool.isRequired,
  onSelectedChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isRating: PropTypes.bool,
};

export default TimePeriodDataTableRow;
